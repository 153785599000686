<template>
    <div>
        <iframe :src="frame" width="100%" height="1200px" frameborder="0"></iframe>
    </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import font from '@/components/prints/font.json'
import fontBold from '@/components/prints/fontBold.json'
import { formatPrice, sumdate } from '@/services/filters'
import { api } from "@/services/api"

export default {
    setup() {
        const { route } = useRouter()
        const fonts = ref(font)
        const fontsBold = ref(fontBold)
        const fontTitle = ref(16)
        const fontBody = ref(14)
        const lastTable = ref(0)
        const shopData = ref({})
        const frame = ref('')

        onMounted(() => {
            shopData.value = JSON.parse(localStorage.getItem('shop'))
            api.get({
                path: '/printTransportation',
                param: `?order_id=${ route.value.query.id.split(',') }&lang=${ route.value.query.lang }&currency_id=${ localStorage.getItem('currencyId') }`,
            }).then(({ data }) => {
                savePDF(data)
            }).catch((err) => {
                console.log(err)
            })
        })

        const savePDF = data => {
            const doc = new jsPDF({
                orientation: 'landscape',
                unit: 'mm',
                format: 'a5',
            })

            doc.setProperties({
                title: `ใบส่งของ `,
            })

            doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
            doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
            doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'normal')
            doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
            doc.setFont('TH-Niramit', 'bold')

            for (let i = 0; i < data.length; i++) {
                let spacer = 10
                let currentY = 25
                let currentX = 15
                let spacer1 = 10
                let currentY1 = 90
                let currentX1 = 195
                doc.setFontSize(22)
                doc.setFont('TH-Niramit', 'bold')
                doc.text('ชื่อและที่อยู่ผู้ส่ง', currentX, 15)
                doc.setFontSize(fontTitle.value)
                doc.setFont('TH-Niramit', 'normal')
                doc.text('ชื่อ : ', currentX, currentY)
                doc.text(data[i].shop_name, 24, currentY)
                spacer = 8
                currentY += spacer
                doc.text('เบอร์โทรศัพท์ : ', currentX, currentY)
                doc.text(data[i].shop_phone, 42, currentY)
                currentY += spacer
                doc.text('ที่อยู่ : ', currentX, currentY)
                doc.text(`${ data[i].shop_address } ${ data[i].shop_subdistrict } ${ data[i].shop_district }`, 27, currentY)
                currentY += spacer
                doc.text(`${ data[i].shop_province } ${ data[i].shop_zipcode }`, 15, currentY)
                currentY += spacer
                doc.setLineWidth(0.1);

                // --------------------------------------------------

                doc.setFontSize(22)
                doc.setFont('TH-Niramit', 'bold',)
                doc.text('ชื่อและที่อยู่ผู้ส่ง', currentX1, 80, null, null, 'right')
                doc.setFontSize(fontTitle.value)
                doc.setFont('TH-Niramit', 'normal')
                doc.text(`ชื่อ : ${ data[i].order_transportation_name }`, currentX1, currentY1, null, null, 'right')
                spacer = 8
                currentY1 += spacer
                doc.text(`เบอร์โทรศัพท์ : ${ data[i].order_transportation_phone }`, currentX1, currentY1, null, null, 'right')
                currentY1 += spacer
                doc.text(`ที่อยู่ : ${ data[i].order_transportation_address } ${ data[i].order_transportation_subdistrict } ${ data[i].order_transportation_district }`, currentX1, currentY1, null, null, 'right')
                currentY1 += spacer
                doc.text(`${ data[i].order_transportation_province } ${ data[i].order_transportation_zipcode }`, currentX1, currentY1, null, null, 'right')
                doc.addPage(1)
            }
            doc.deletePage(data.length + 1)
            frame.value = doc.output('datauristring', { filename: `hello.pdf` })
        }

        return { savePDF, frame }
    },
}
</script>
